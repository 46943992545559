import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Паб та пивоварня Бургомістр
			</title>
			<meta name={"description"} content={"ідеальне поєднання спорту та крафтового пива в Бургомістр"} />
			<meta property={"og:title"} content={"Паб та пивоварня Бургомістр"} />
			<meta property={"og:description"} content={"ідеальне поєднання спорту та крафтового пива в Бургомістр"} />
			<meta property={"og:image"} content={"https://opamakinst.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://opamakinst.com/img/2559186-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileColor"} content={"https://opamakinst.com/img/2559186-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="--headline1">
				Знайти Нас
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="20px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						Пориньте у атмосферу поєднання спорту та крафтового пива у пабі та пивоварні "Бургомістр". Це місце, де азарт гри поєднується із задоволенням від холодного пива.
					</Text>
					<Image src="https://opamakinst.com/img/map121124.png" display="block" width="100%" />
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="normal 700 36px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Адреса
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 28px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						вул. Тараса Шевченка 28б, Трускавець, 82200
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 36px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Телефон
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 28px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						096 285 5014
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 700 36px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Email
					</Text>
					<Text margin="8px 0px 20px 0px" font="normal 300 28px/1.5 --fontFamily-sansHelvetica" color="#35363a">
						burgomistr@opamakinst.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});